(function ($) {
    $(function () {
        var $subnav = $('.mn__item > .sub-nav > ul');

        $('.owl-carousel').each(function () {
           // var options = (typeof $(this).attr('data-options') !== "undefined") ? $.parseJSON($(this).attr('data-options')) : {};
            var options = (typeof $(this).attr('data-options') !== "undefined") ? JSON.parse($(this).attr('data-options')) : {};
            
            options.navText = [
              '<button type="button" class="icon-chevron left" aria-hidden="true"><span class="sr-only">Previous Button</span></button>',
              '<button type="button" class="icon-chevron right" aria-hidden="true"><span class="sr-only">Next Button</span></button>'
            ];

            options.nav = true;
            options.responsive = {
                0: {
                    items: 1,
                },
                768: {
                    items: (options.items > 1) ? 2 : options.items,
                },
                1024: {
                    items: options.items,
                }
            }

            $(this).owlCarousel(options);
        });

        Respond.to({
            'media': '(max-width: 1199px)',
            'namespace': '1199_menu_carousel',
            'fallback': 'else',
            'if': function () {
                $subnav.each(function () {
                    var $li = $(this).find('li');

                    if ($li.length > 7 && $(this).data('owl.carousel')) {
                        $(this).data('owl.carousel').destroy();
                    }
                });
            },
            'else': function () {
                $subnav.each(function () {
                    var $li = $(this).find('> li');

                    if ($li.length > 7) {
                        $(this).owlCarousel({
                            margin: 30,
                            items: 7,
                            navText: [
                              '<button type="button" class="icon-arrow-left left" aria-hidden="true"><span class="sr-only">Previous</span></button>',
                              '<button type="button" class="icon-arrow-left right" aria-hidden="true"><span class="sr-only">Next</span></button>'
                            ]
                        });
                    }
                });
            }
        });
    });
})(jQuery);
